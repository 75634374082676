import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useReportList() {
  const dataSourceList = ref([])
  const reportList = ref([])
  const groupFilter = ref([])
  const loading = ref(false)

  const reportData = ref({})
  const singleReport = ref({})

  const selectedRow = ref([])
  const searchQuery = ref('')

  const dateRangeList = ref(['Day', 'Week', 'Month', 'All'])

  const options = ref({
    sortBy: ['reportName'],
    sortDesc: [false],
  })

  const tableColumns = [
    { text: 'NAME', value: 'reportStyle.reportName' },
    { text: 'DESCRIPTION', value: 'reportStyle.reportDescription' },
    { text: 'GROUP', value: 'reportStyle.reportGroup' },
    { text: 'RANGE', value: 'reportStyle.dateRange' },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  // fetch data
  const fetchDataSources = () => {
    console.log('Reached Service Store')
    store
      .dispatch('app-report/fetchDataSources')
      .then(response => {
        dataSourceList.value = response

        console.log(dataSourceList.value)

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    loading.value = true
    fetchDataSources()
  })

  // fetch data source Data
  const fetchDataSourceData = (entityName, startDate, endDate) => {
    const payload = ref({
      entityName,
      startDate,
      endDate,
    })
    loading.value = true
    console.log('Reached Service Store', payload.value, loading.value)
    store
      .dispatch('app-report/fetchDataSourceData', payload.value)
      .then(response => {
        reportData.value = response
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    loading.value = true
    fetchDataSourceData()
  })

  // fetch reports
  const fetchReports = () => {
    store
      .dispatch('app-report/fetchReports')
      .then(response => {
        reportList.value = []

        if (groupFilter.value.length !== 0) {
          groupFilter.value.forEach(group => {
            reportList.value = response.filter(report => report.reportStyle.reportGroup === group)
          })
        } else {
          reportList.value = response
        }

        console.log('Report Response', response)
        console.log('Report List', reportList.value)

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([groupFilter], () => {
    loading.value = true
    selectedRow.value = []
    fetchReports()
  })

  const fetchSiteReport = reportName => {
    store
      .dispatch('app-report/fetchSiteReport', reportName)
      .then(response => {
        singleReport.value = response

        console.log('Report Response', singleReport.value.length)
        console.log('Report', singleReport.value)

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    loading.value = true
    fetchSiteReport()
  })

  // post data
  const postReport = (reportConfig, reportSite) => {
    const reportStyleDTO = ref({
      reportName: reportSite.reportName,
      reportDescription: reportSite.reportDescription,
      reportGroup: reportSite.reportGroup,
      reportConfig,
      dataSources: reportSite.dataSources,
    })
    console.log(reportStyleDTO.value)

    store
      .dispatch('app-report/postReport', reportStyleDTO.value)
      .then(response => {
        console.log(response)

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    postReport()
  })

  return {
    loading,
    groupFilter,
    selectedRow,
    tableColumns,
    searchQuery,
    options,

    fetchDataSources,
    dataSourceList,

    fetchReports,
    reportList,

    fetchDataSourceData,
    reportData,

    postReport,

    fetchSiteReport,
    singleReport,

    dateRangeList,
  }
}
