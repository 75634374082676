<template>
  <div>
    <v-card>
      <v-card-text>
        <div>
          <v-row
            justify="center"
          >
            <v-col
              md="auto"
            >
              <v-btn
                color="error"
                @click="close(false)"
              >
                Close
              </v-btn>
            </v-col>

            <v-spacer></v-spacer>

            <v-menu
              ref="menuref"
              v-model="menuID"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template
                v-slot:activator="{ on, attrs }"
              >
                <v-text-field
                  v-model="startDate"
                  :prepend-icon="icons.mdiCalendar"
                  :readonly="!isEdit"
                  label="Start Date"
                  hint="YYYY-MM-DD"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  @change="refetchData"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="startDate"
                :show-current="true"
                color="primary"
                @change="refetchData"
              ></v-date-picker>
            </v-menu>

            <v-menu
              ref="menuref"
              v-model="menuER"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template
                v-slot:activator="{ on, attrs }"
              >
                <v-text-field
                  v-model="endDate"
                  :prepend-icon="icons.mdiCalendar"
                  :readonly="!isEdit"
                  label="End Date"
                  hint="YYYY-MM-DD"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  @change="refetchData"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="endDate"
                :show-current="true"
                color="primary"
                reactive
                @change="refetchData"
              ></v-date-picker>
            </v-menu>

            <v-spacer></v-spacer>

            <v-col
              md="auto"
            >
              <v-btn
                color="primary"
                @click="exportReport('excel')"
              >
                Excel
              </v-btn>
            </v-col>
            <v-col
              md="auto"
            >
              <v-btn
                color="success"
                @click="exportReport('html')"
              >
                HTML
              </v-btn>
            </v-col>
            <v-col
              md="auto"
            >
              <v-btn
                color="secondary"
                @click="exportReport('pdf')"
              >
                PDF
              </v-btn>
            </v-col>
            <v-col
              v-if="isEdit"
              md="auto"
            >
              <v-btn
                color="primary"
                @click="postReport()"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      id="report-render"
    >
      <Pivot
        height="700"
        :report="report"
        :toolbar="isEdit"
        :customizeCell="customizeCellFunction"
      >
      </Pivot>
    </v-card>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'
import moment from 'moment'
import store from '@/store'

import reportService from '@/services/ReportService'
import useReportService from '@/store/useReportService'

export default {
  name: 'report-render',
  props: {
    data: {
      type: Array,
      default: () => ({}),
    },
    reportStyle: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'DetainEHR Report',
    },
    source: {
      type: String,
      default: null,
    },
    form: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const REPORT_APP = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_APP)) {
      store.registerModule(REPORT_APP, reportService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP)) {
        store.unregisterModule(REPORT_APP)
      }
    })

    const { fetchDataSourceData, reportData } = useReportService()

    const jsonData = props.data

    const menuID = ref(false)
    const menuER = ref(false)
    const menuref = ref(null)

    const startDate = ref(moment(new Date()).format('YYYY-MM-DD'))
    const endDate = ref(moment(new Date()).format('YYYY-MM-DD'))

    console.log('Report Render', jsonData, props.reportStyle)
    let report = ref(null)

    if (jsonData != null && jsonData !== undefined) {
      report = props.reportStyle
    } else {
      report = ref({
        dataSource: {
          type: 'json',
          data: jsonData,
        },
        slice: {
          rows: [
            {
              uniqueName: 'IsActive',
              filter: {
                members: [
                  'IsActive.true',
                ],
              },
              sort: 'asc',
            },
          ],
        },
      })
    }

    console.log('Finalized Report Data', jsonData, report)

    const close = () => {
      emit('update-dialog', '')
    }

    const postReport = () => {
      const reportConfig = webdatarocks.getReport({
        withDefaults: true,
        withGlobals: true,
      })
      console.log('Report Config', reportConfig)
      emit('report-config', reportConfig)
      this.close()
    }

    const exportReport = type => {
      const user = JSON.parse(localStorage.getItem('userData')).userName
      if (type === 'html' || type === 'pdf') {
        webdatarocks.exportTo(type, {
          filename: props.name,
          pageOrientation: 'landscape',
          footer: user + "<div>##CURRENT-DATE##</div>",
        },
        function () {
          console.log('Export process is finished')
        })
      }
      else if (type === 'excel') {
        console.log('Export Begining')
        webdatarocks.exportTo(type, {
          filename: props.name,
          excelSheetName: props.name,
          showFilters: true,
        },
        function () {
          console.log('Export process is finished')
        })
      }
    }

    return {
      report,
      jsonData,

      menuID,
      menuER,
      menuref,
      startDate,
      endDate,

      close,
      postReport,
      exportReport,

      fetchDataSourceData,
      reportData,

      icons: {
        mdiCalendar,
      },
    }
  },
  mounted() {

    webdatarocks.setOptions({
      dateTimePattern: 'yyyy-MM-ddTHH:MM:SSZ',
      datePattern: 'yyyy-MM-dd',
    })

    webdatarocks.on('reportcomplete', function() {
      console.log('Report Loaded!')
    })

    webdatarocks.on('update', function() {
      console.log('Report Updated!', this.report)
    })

    if(this.report != null){
      console.log('report Not Null', this.report)
      //this.export()
    }
  },
  methods:{
    customizeCellFunction(cellBuilder, cellData) {
      let index = cellData.rowIndex - 1

      if (cellData.hierarchy != null){
        if (cellData.type == "value" && cellData.hierarchy.uniqueName == "Patient.JacketId") {
          cellBuilder.text = this.report.dataSource.data[index]["Patient.JacketId"]
        }

        if (cellData.type == "value" && cellData.hierarchy.uniqueName == "Patient.BookingId") {
          cellBuilder.text = this.report.dataSource.data[index]["Patient.BookingId"]
        }

        if (cellData.type == "value" && cellData.hierarchy.uniqueName == "JacketId") {
          cellBuilder.text = this.report.dataSource.data[index]["JacketId"]
        }

        if (cellData.type == "value" && cellData.hierarchy.uniqueName == "BookingId") {
          cellBuilder.text = this.report.dataSource.data[index]["BookingId"]
        }
      }

      index = index + 1
    },
    refetchData(){
      console.log('Refetching Report', this.source, this.startDate, this.endDate)
      this.fetchDataSourceData(this.source, this.startDate, this.endDate)

      this.$watch('reportData', () => {
        this.report.dataSource.data = this.reportData

        console.log(this.reportData, this.report)
        webdatarocks.setReport(this.report)

        webdatarocks.refresh()
      })
    },
    export(){
      console.log('export')
      this.exportReport('excel')
    }
  }
}

</script>
