import ApiConfig from './ApiConfig'
import { ReportClient_V1 } from './generated/api.generated.v1'
import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Data Source
    fetchDataSources() {
      const api = new ReportClient_V1(new ApiConfig(), variables.INTERNAL_API)

      console.log('Got to request')

      return new Promise((resolve, reject) => {
        api
          .getDataSources()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchDataSourceData(context, payload) {
      const api = new ReportClient_V1(new ApiConfig(), variables.INTERNAL_API)

      console.log('Got to request', payload)

      return new Promise((resolve, reject) => {
        api
          .getDataSourceData(payload.entityName, payload.startDate, payload.endDate)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Report
    fetchReports() {
      const api = new ReportClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteReports()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSiteReport(context, payload) {
      const api = new ReportClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteReport(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postReport(context, payload) {
      const api = new ReportClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postReport(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
